import React, { lazy } from 'react';

const InvoiceToSupplier = lazy(() =>
  import('../SupplierAccountListing/SupplierAccountListing').then(module => ({
    default: module.SupplierAccountListing,
  })),
);

const SupplierLedgerForm = lazy(() =>
  import('../SupplierAccountFormV2/InvoicetoSupplierForm').then(module => ({
    default: module.InvoicetoSupplierForm,
  })),
);

const InvoiceToSupplierPreviewDetailPDF = lazy(() =>
  import('../SupplierAccountPDF/InvoicetoSupplierPreviewDetailPDF').then(
    module => ({
      default: module.InvoiceToSupplierPreviewDetailPDF,
    }),
  ),
);

const SupplierLedgerDetail = lazy(() =>
  import('../SupplierAccountDetail/InvoicetoSupplierDetail').then(module => ({
    default: module.InvoicetoSupplierDetail,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({ default: module.GLItemForm }),
  ),
);

export const InvoicetoSupplierRoutes = [
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-to-supplier`,
    },
    component: (
      <InvoiceToSupplier
        accountType="supplier"
        transactionType="Invoices to Supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/:ID/invoices-to-supplier`,
    },
    component: (
      <InvoiceToSupplier
        accountType="supplier"
        transactionType="Invoices to Supplier"
        supplierType="withID"
      />
    ),
  },
  ///////////////
  //// FORMS ////
  ///////////////
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/add',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices to Supplier"
        accountType="supplier"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-to-supplier/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-to-supplier/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="invoices-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-to-supplier/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: `/supplier-account/account-transaction/invoices-to-supplier/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="invoices-to-supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/edit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices to Supplier"
        accountType="supplier"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/approve-reject/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
      />
    ),
  },

  // workdesk

  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices to Supplier"
        accountType="supplier"
        formMode="approve-reject"
        mode="approval"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/resubmit/:transactionID',
    },
    component: (
      <SupplierLedgerForm
        transactionType="Invoices to Supplier"
        accountType="supplier"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices to Supplier"
        accountType="supplier"
        supplierType="withoutID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/invoices-to-supplier/detail/workdesk/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices to Supplier"
        accountType="supplier"
        supplierType="withoutID"
        modeType="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/invoices-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices to Supplier"
        accountType="supplier"
        supplierType="withID"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/supplier/submenu/:ID/supplier-ledger/invoices-to-supplier/detail/:transactionID',
    },
    component: (
      <SupplierLedgerDetail
        transactionType="Invoices to Supplier"
        accountType="supplier"
        supplierType="withID"
        modeType="ledger"
      />
    ),
  },

  //PDF Preview Routing by Aqil
  {
    props: {
      exact: true,
      path: '/supplier-account/account-transaction/:ID/invoices-to-supplier/:transactionID/preview',
    },
    component: (
      <InvoiceToSupplierPreviewDetailPDF
        transactionType="Invoices to Supplier"
        accountType="supplier"
      />
    ),
  },
];
