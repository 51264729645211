import { useGetDrawerPermissionLazyQuery } from '@contract-root/admin-react/src/generated/graphql';
import ClientApp from '@ifca-root/react-component/src/assets/contractIcons/app/client.svg';
import SubconApp from '@ifca-root/react-component/src/assets/contractIcons/app/subcontractor.svg';
import SupplierApp from '@ifca-root/react-component/src/assets/contractIcons/app/suppliers.svg';
import ClientProfile from '@ifca-root/react-component/src/assets/icons/Sidemenu/client.svg';
import MyProfile from '@ifca-root/react-component/src/assets/icons/Sidemenu/my-profile.svg';
import SubconProfile from '@ifca-root/react-component/src/assets/icons/Sidemenu/subcon.svg';
import SupplierProfile from '@ifca-root/react-component/src/assets/icons/Sidemenu/supplier.svg';
import AccessSecurity from '@ifca-root/react-component/src/assets/icons/SystemAdmin/access-sec.svg';
import CompanySetup from '@ifca-root/react-component/src/assets/icons/SystemAdmin/company-setup.svg';
import GeneralSetting from '@ifca-root/react-component/src/assets/icons/SystemAdmin/general-setting.svg';
import CompanyActive from '@ifca-root/react-component/src/assets/icons/company-w.svg';
import DigitalReporting from '@ifca-root/react-component/src/assets/icons/digital-reporting.svg';
import GeneralSettingActive from '@ifca-root/react-component/src/assets/icons/general-setting-w.svg';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smallTitle } from '@ifca-root/react-component/src/helpers/Global/ContractGlobalVar';
import { navigateReactUrl } from '@ifca-root/react-component/src/utils/hooks/navigateReactUrl';
import {
  Avatar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { ExpandMore, KeyboardArrowRight } from '@material-ui/icons';
import GroupBI from 'assets/icons/HomeApp/group-biz-insight.svg';
import SiteMgmt from 'assets/icons/HomeApp/site-mgmt-icon.svg';
import SystemAdmin from 'assets/icons/HomeApp/system-admin.svg';
import Home from 'assets/icons/SideMenu/home.svg';
import ContractSetup from 'assets/icons/SideMenu/project-setup.svg';
import Profile from 'assets/icons/SideMenu/user-profile.svg';
import HomeActive from 'assets/icons/home-w.svg';
import Logout from 'assets/icons/logout.svg';
import { externalHomeRoutes } from 'containers/HomeModule/Function/ExternalHomeRoutes';
import {
  ContPermission,
  useLoggedInUserProfileLazyQuery,
} from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: { flexGrow: 1, padding: theme.spacing(3) },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

interface Props {
  window?: () => Window;
  open?: boolean;
  variant?: any;
  onCloseDrawer?: any;
}
interface NavigationProps {
  name: string;
  path: string;
  icon: any;
  iconSelected: any;
  reroute: boolean;
  reroutePath: string;
  deployed: boolean;
  permission: any;
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props;
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  let history = useHistory();
  const { signOut } = useBroadcastChannel();
  const mode: any = history?.location?.pathname;

  let isHomeMenu: boolean = false,
    isHomeGroup: boolean = false;

  const isMainHomeMenuDrawer =
    mode === '/' ||
    mode === '/home' ||
    mode === '/group-bi' ||
    mode === '/access-security' ||
    mode === '/common-settings' ||
    mode === '/company-setup';

  if (mode === '/alternate') isHomeMenu = true;

  if (isMainHomeMenuDrawer) isHomeGroup = true;

  const [
    fetchUserInfo,
    { loading, data: { loggedInUserProfile } = { loggedInUserProfile: null } },
  ] = useLoggedInUserProfileLazyQuery({ fetchPolicy: 'network-only' });

  const [
    fetchDrawer,
    {
      loading: drawerLoading,
      data: {
        getDrawerPermission: {
          groupBI,
          accessSecurity,
          commonSetting,
          companySetup,
          generalSetting,
          contractSetting,
        },
      } = {
        getDrawerPermission: {
          groupBI: false,
          accessSecurity: false,
          commonSetting: false,
          companySetup: false,
          generalSetting: false,
          contractSetting: false,
        },
      },
    },
  ] = useGetDrawerPermissionLazyQuery({
    fetchPolicy: 'network-only',
  });

  const pageMode = reconsRoute(mode);

  let genSettings = 'contract';

  if (
    pageMode == 'access-security' ||
    pageMode === 'company-setup' ||
    pageMode === 'common-settings'
  ) {
    genSettings = 'contract';
  } else genSettings = pageMode;

  let isPathForContSett = [
    '/contract',
    '/client-account',
    '/subcontract',
    '/subcontractor-account',
    '/supplier',
    '/client-contract',
    '/project-costing',
    '/profit-recognition',
  ];

  const checkPathConSet = mode => {
    let k = isPathForContSett.filter(k => mode?.includes(k));
    return k.length > 0;
  };

  const isSysAdmin = ['/system-admin'];

  const checkSysAdmin = mode => {
    let k = isSysAdmin.filter(k => mode?.includes(k));
    return k.length > 0;
  };

  // LOCALHOST NAVIGATION
  const {
    siteReactUrl,
    clientReactUrl,
    subcontractorReactUrl,
    supplierReactUrl,
  } = externalHomeRoutes();

  const defaultHomeMenu = [
    {
      name: 'Home Page',
      path: '/home',
      icon: Home,
      iconSelected: HomeActive,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: true,
    },
  ];

  const defaultLogoutMenu = [
    {
      name: 'Logout',
      path: '/logout',
      icon: Logout,
      iconSelected: Logout,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: true,
    },
  ];

  const defaultMyProfileMenu = [
    {
      name: 'My Profile',
      path: '/profile',
      icon: MyProfile,
      iconSelected: MyProfile,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: true,
    },
  ];

  const defaultGenSettingsMenu = [
    {
      name: 'General Settings',
      path: `/${genSettings}/general-settings`,
      icon: GeneralSetting,
      iconSelected: GeneralSettingActive,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: generalSetting,
    },
  ];

  const defaultConSettingsMenu = [
    {
      name:
        pageMode === 'profit-recognition'
          ? 'Contract Settings'
          : 'Contract Approval Policy',
      path: `/${pageMode}/contract-settings`,
      icon: ContractSetup,
      iconSelected: ContractSetup,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: contractSetting,
    },
  ];

  const defaultGroupBIMenu = [
    {
      name: 'Group Business Insight',
      path: '/group-bi',
      icon: GroupBI,
      iconSelected: GroupBI,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: groupBI,
    },
  ];

  const defaultAccessSecurity = [
    {
      name: 'Access Security',
      path: `/access-security`,
      icon: AccessSecurity,
      iconSelected: AccessSecurity,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: accessSecurity,
    },
  ];

  const defaultCommonSettings = [
    {
      name: 'Common Settings',
      path: `/common-settings`,
      icon: SystemAdmin,
      iconSelected: SystemAdmin,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: commonSetting,
    },
  ];

  const defaultCompanySetupMenu = [
    {
      name: `Company Setup`,
      path: `/company-setup`,
      icon: CompanySetup,
      iconSelected: CompanyActive,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: companySetup,
    },
  ];

  const defaultGeneralSettingsMenu = [
    {
      name: `General Settings`,
      path: `/contract/general-settings`,
      icon: GeneralSetting,
      iconSelected: GeneralSetting,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: generalSetting,
    },
  ];

  const defaultFinancialReportsMenu = [
    {
      name: `Financial Reports`,
      path: `/general-ledger/financial-reporting`,
      icon: DigitalReporting,
      iconSelected: GeneralSetting,
      reroute: false,
      reroutePath: '',
      deployed: process.env.REACT_APP_LOCAL_DB === 'prod' ? false : true,
      permission: generalSetting,
    },
  ];

  const defaultSystemAdminMenu = [
    {
      name: smallTitle.SYSTEM_ADMIN,
      path: `/system-admin`,
      icon: SystemAdmin,
      iconSelected: SystemAdmin,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: ContPermission.SystemAdminIcon,
    },
  ];
  const defaultAssociateProfileMenu = [
    {
      name: 'Client Profile',
      path: `/client-profile`,
      icon: ClientProfile,
      iconSelected: ClientProfile,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: ContPermission.GeneralSettingsIcon,
    },
    {
      name: 'Subcon Profile',
      path: `/subcontractor-profile`,
      icon: SubconProfile,
      iconSelected: SubconProfile,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: ContPermission.GeneralSettingsIcon,
    },
    {
      name: 'Supplier Profile',
      path: `/supplier-profile`,
      icon: SupplierProfile,
      iconSelected: SupplierProfile,
      reroute: false,
      reroutePath: '',
      deployed: true,
      permission: ContPermission.GeneralSettingsIcon,
    },
  ];

  const navigations: any = isHomeMenu
    ? [
        ...defaultHomeMenu,
        ...defaultGroupBIMenu,
        ...defaultAccessSecurity,
        ...defaultCommonSettings,
        ...defaultCompanySetupMenu,
        {
          name: `Site Management`,
          path: `/login`,
          icon: SiteMgmt,
          iconSelected: SiteMgmt,
          reroute: true,
          reroutePath: siteReactUrl,
          deployed: true,
          permission: true,
        },
        {
          name: 'Client App',
          path: '/login',
          icon: ClientApp,
          iconSelected: ClientApp,
          reroute: true,
          reroutePath: clientReactUrl,
          deployed: process.env.REACT_APP_LOCAL_DB === 'prod' ? false : true,
          permission: true,
        },
        {
          name: 'Subcon App',
          path: '/login',
          icon: SubconApp,
          iconSelected: SubconApp,
          reroute: true,
          reroutePath: subcontractorReactUrl,
          deployed: process.env.REACT_APP_LOCAL_DB === 'prod' ? false : true,
          permission: true,
        },
        {
          name: 'Supplier App',
          path: '/login',
          icon: SupplierApp,
          iconSelected: SupplierApp,
          reroute: true,
          reroutePath: supplierReactUrl,
          deployed: process.env.REACT_APP_LOCAL_DB === 'prod' ? false : true,
          permission: true,
        },
        ...defaultMyProfileMenu,
        ...defaultLogoutMenu,
      ]
    : isHomeGroup
    ? [
        ...defaultHomeMenu,
        ...defaultGroupBIMenu,
        ...defaultCompanySetupMenu,
        ...defaultAccessSecurity,
        ...defaultCommonSettings,
        ...defaultGeneralSettingsMenu,
        ...defaultFinancialReportsMenu,
        ...defaultLogoutMenu,
      ]
    : checkPathConSet(mode) && !checkSysAdmin(mode)
    ? [
        ...defaultHomeMenu,
        ...defaultGenSettingsMenu,
        ...defaultConSettingsMenu,
        ...defaultLogoutMenu,
      ]
    : checkSysAdmin(mode) || mode === '/profile'
    ? [...defaultHomeMenu, ...defaultLogoutMenu]
    : [...defaultHomeMenu, ...defaultGenSettingsMenu, ...defaultLogoutMenu];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const handleProfileClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirectProfile = () => {
    if (!!onCloseDrawer) onCloseDrawer();
    setAnchorEl(null);
    history.push('/profile');
  };

  useEffect(() => {
    if (!user?.avatar || user === null) fetchUserInfo();
  }, [user?.ID]);

  useEffect(() => {
    if (mode === '/home' || mode === '/' || pageMode)
      fetchDrawer({ variables: { pageMode: pageMode } });
  }, [mode]);

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations?.map((v, index) => {
          if (!!v?.deployed && v?.permission)
            return (
              <ListItem
                button
                key={index}
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={{ pathname: v?.reroute ? '' : v?.path }}
                onClick={() => {
                  if (v?.reroute) navigateReactUrl(v?.reroutePath, v?.path);
                  if (v?.path === `/${pageMode}/contract-settings`)
                    localStorage.setItem('backPath', mode);
                  else localStorage.removeItem('backPath');

                  if (!!onCloseDrawer) onCloseDrawer();

                  if (v?.name === 'Logout') {
                    signOut(user?.ID);
                    // sessionStorage.clear();
                    // sessionStorage.removeItem('accessToken');
                  }
                  localStorage.removeItem('searchDropdown');
                  localStorage.removeItem('searchFilter');
                }}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v?.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v?.name} />
              </ListItem>
            );
        })}
      </List>
    </div>
  );

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {!!user?.avatar ? (
          <img src={user?.avatar} alt="" className="avatar" />
        ) : !user?.avatar && !!loggedInUserProfile?.avatar ? (
          <img src={loggedInUserProfile?.avatar} alt="" className="avatar" />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={
              <span className="xsTitle text-noflow">
                {`${user?.name || loggedInUserProfile?.name}
                (${user?.userName || loggedInUserProfile?.userName})`}
              </span>
            }
            secondary={
              <span className="desc">
                {user?.email || loggedInUserProfile?.email}
              </span>
            }
          />
          {isHomeMenu || isHomeGroup ? (
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="arrow">
                <KeyboardArrowRight onClick={handleRedirectProfile} />
              </IconButton>
            </ListItemSecondaryAction>
          ) : (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="simple-menu"
                onClick={handleProfileClick}
              >
                <ExpandMore />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
          <img className="icon-dropdown-svg" src={Profile} alt="" />
          Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={() => {
            signOut(user?.ID || loggedInUserProfile?.ID);
            // sessionStorage.clear();
            // sessionStorage.removeItem('accessToken');
          }}
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );

  // Better open performance on mobile.
  // ModalPropers={{ keepMounted: true }}
  return (
    <>
      {loading && <Loading />}
      {drawerLoading && <Loading />}
      <nav className={classes.drawer} aria-label="main menu">
        <Drawer
          {...rest}
          container={container}
          variant={variant}
          anchor={'left'}
          open={open}
          onClose={onCloseDrawer}
          className="drawer"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
        >
          {userProfile}
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

// order of this is important
const mode = [
  'contract',
  'client-account',
  'project-costing',
  'profit-recognition',
  'system-admin',
  'gl-interface',
  'client-contract',
  'plant-machinery',
  'subcon-tender',
  'subcontract',
  'subcontractor',
  'supplier',
  'access-security',
  'common-settings',
  'company-setup',
  'fuel-consumption',
];

export const isIn = (route: string, module: string) => {
  return route.match(module);
};

export const reconsRoute = (route: string) => {
  let genRoute = mode.filter(x => isIn(route, x));
  return genRoute.length > 0 ? genRoute[genRoute.length - 1] : null;
};
