import React, { lazy } from 'react';

const FinancialReportingSubmenu = lazy(() =>
  import('./FinancialReportingSubmenu').then(module => ({
    default: module.FinancialReportingSubmenu,
  })),
);

const FinancialReportingListing = lazy(() =>
  import('./FinancialReportingListing').then(module => ({
    default: module.FinancialReportingListing,
  })),
);

// Data Sources
const FRDataSourcesListing = lazy(() =>
  import('./FinancialReportingSettings/FRDataSourcesListing').then(module => ({
    default: module.FRDataSourcesListing,
  })),
);

const FRDataSourcesForm = lazy(() =>
  import('./FinancialReportingSettings/FRDataSourcesForm').then(module => ({
    default: module.FRDataSourcesForm,
  })),
);

// Formula Tools
const FRReportFormulaToolsListing = lazy(() =>
  import('./FinancialReportingSettings/FRReportFormulaToolsListing').then(
    module => ({
      default: module.FRReportFormulaToolsListing,
    }),
  ),
);

// Report Format
const FRReportFormatListing = lazy(() =>
  import('./FinancialReportingSettings/FRReportFormatListing').then(module => ({
    default: module.FRReportFormatListing,
  })),
);

const financialReportingRoutes = [
  {
    props: { exact: true, path: '/general-ledger/financial-reporting' },
    component: <FinancialReportingSubmenu />,
  },
  {
    props: { exact: true, path: '/general-ledger/financial-reporting/listing' },
    component: <FinancialReportingListing />,
  },
  // Data Source
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/data-sources/listing',
    },
    component: <FRDataSourcesListing />,
  },
  // Formula Tools
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/formula-tools/listing',
    },
    component: <FRReportFormulaToolsListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/data-sources/form',
    },
    component: <FRDataSourcesForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-ledger/financial-reporting/data-sources/:dataSourcesID/form',
    },
    component: <FRDataSourcesForm mode="edir" />,
  },
  // Report Format
  {
    props: {
      exact: true,
      path: '/general-ledger/financial-reporting/report-format/listing',
    },
    component: <FRReportFormatListing />,
  },
];

export default financialReportingRoutes;
